.lock-scroll {
  overflow: hidden;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #262626;
  border-bottom: solid 2px #c5a975;
}

.navbar {
  display: flex;
  height: 150px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  width: 400px;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
}

.nav-item {
  margin: 2px;
  font-size: 1rem;
  font-weight: bold;
}

.nav-items {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.nav-links:hover {
  color: #c5a975;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.nav-links {
  color: #777777;
  text-decoration: none;
}

.navbar-logo {
  margin-left: 30px;
}

.logo-nav-img {
  height: 100px;
  width: auto;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: white;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    z-index: 2;
    margin-right: 10%;
  }

  .hamburger.active {
    position: fixed;
    margin-left: 85vw;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .nav-menu {
    position: fixed;
    left: -110%;
    gap: 0;
    top: 0px;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: rgba(26, 26, 26, 0.8);
    backdrop-filter: blur(1rem);
    width: 100%;
    text-align: center;
    transition: 0.3s;
    height: 100vh;
    z-index: 1;
    display: block;
  }

  .nav-items {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .nav-item {
    justify-content: center;
    align-items: center;
    height: 25%;
    width: 100%;
  }

  .nav-links {
    color: #777777;
    font-size: 30px;
    text-decoration: none;
    margin: 1px 0;
    width: 100%;
    height: 100%;
    border-bottom: solid #c5a975;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-menu.active {
    left: 0;
  }
}