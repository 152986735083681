html * {
    font-family: 'Poppins', sans-serif;
}

.hero-section-container {
    position: relative;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: solid 2px #c5a975;
    overflow: hidden; /* Ensure that the pseudo-element doesn't overflow */
  }
  
  .hero-section-container::before {
    content: ""; /* Create a pseudo-element */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; /* Send the pseudo-element to the background */
    background-image: url("../../public/images/outside1.jpg");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  
  .hero-section-container::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5));
  }

.hero-section-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section-title-text {
    font-size: 100px;
    font-weight: bolder;
    background-image: linear-gradient(0deg, #ffdd9e, #6e5a3b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.hero-section-subtitle-text {
    font-size: 50px;
    font-weight: bolder;
    background-image: linear-gradient(180deg, #ffdd9e, #6e5a3b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

@media only screen and (max-width: 650px) {
    .hero-section-title-text {
        font-size: 70px;
    }
    
    .hero-section-subtitle-text {
        font-size: 25px;
    }
}

@media only screen and (max-width: 500px) {
    .hero-section-container {
        position: relative;
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: solid 2px #c5a975;
        overflow: hidden; /* Ensure that the pseudo-element doesn't overflow */
      }
      
      .hero-section-container::before {
        content: ""; /* Create a pseudo-element */
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1; /* Send the pseudo-element to the background */
        background-image: url("../../public/images/outside1.jpg");
        background-position: -100px 0px;
        background-repeat: no-repeat;
        background-size: 300%;
        background-attachment: fixed;
      }
      
      .hero-section-container::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5));
      }
}