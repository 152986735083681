.about-container {
    display: flex;
    align-items: center;
    animation-name: background-anim;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    padding: 5%;
    width: 100%;
}

.about-content-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.logo-about-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 500px;
    background-color: #262626;
    border: solid #c5a975;
}

.logo-about-img {
    width: 100%;
}

.about-text-container {
    width: 50%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    background-color: #262626;
    border: solid #c5a975;
    justify-content: center;
    padding: 2%;
    margin-left: 25px;
}

.about-title {
    font-size: 50px;
    font-weight: bolder;
    background-image: linear-gradient(10deg, #ffdd9e, #6e5a3b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-text {
    margin-top: 25px;
    color: #bbb;
    
}

@media only screen and (max-width: 768px) { 
    .about-content-container {
        flex-direction: column-reverse;
    }

    .logo-about-container {
        width: 100%;
        max-width: 1000px;
    }
    
    .about-text-container {
        width: 100%;
        margin-bottom: 25px;
        margin-left: 0px;
        max-width: 1000px;
    }
}