.privacy-container {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #555555;
    animation-name: background-anim;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.privacy-border {
    background-color: #262626;
    border: solid #c5a975;
    padding: 2%;
}

.privacy-title-container {
    display: flex;
    justify-content: space-between;
}

.privacy-logo-container {
    display: flex;
    align-items: center;
}

.privacy-title-text {
    font-size: 50px;
    font-weight: bolder;
    font-family: 'Dancing Script', cursive;
    color: #c5a975;
    background-image: linear-gradient(179deg, #ffdd9e, #6e5a3b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.privacy-text {
    margin-top: 25px;
    color: #c5a975;
}

.logo-title-img {
    width: 70px;
    height: 70px;
}

@keyframes background-anim {
    0% {
        background-color: #555555;
    }
    25% {
        background-color: #262626;
    }
    50% {
        background-color: #6b5d44;
    }
    75% {
        background-color: #262626;
    }
    100% {
        background-color: #555555;
    }
}