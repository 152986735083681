.contact-page-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    animation-name: background-anim;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    padding-bottom: 100px;
}

.contact-us-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 400px;
}

.contact-us-text-container {
    display: flex;
    flex-direction: column;
    background-color: #262626;
    color: #c5a975;
    border: solid #c5a975;
    height: 250px;
    width: 50%;
    max-width: 500px;
    justify-content: center;
    padding: 30px;
}

.contact-us-title-text {
    font-size: 50px;
    font-weight: bolder;
    color: #c5a975;
}

.contact-us-subtitle-text {
    margin-top: 10px;
    color: #bbb;
}

.contact-us-details-container {
    display: flex;
    flex-direction: column;
    background-color: #262626;
    color: #c5a975;
    border: solid #c5a975;
    height: 250px;
    width: 50%;
    max-width: 500px;
    justify-content: center;
    padding: 2%;
}

.contact-detail {
    display: flex;
    margin: 20px;
}

.contact-detail-title-text {
    font-weight: bolder;
    color: #c5a975;
}

.contact-detail-text {
    margin-left: 5px;
    color: #bbb;
}

.socials {
    color: #bbb;
}

.visit-us-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 1000px;
}

.visit-us-text-container {
    display: flex;
    flex-direction: column;
    background-color: #262626;
    color: #c5a975;
    border: solid #c5a975;
    width: 100%;
    max-width: 1000px;
    justify-content: center;
    padding: 30px;
}

.visit-us-map-container {
    border: solid #c5a975;
    width: 100%;
    max-width: 1000px;
    height: 75%;
    aspect-ratio: 1 / 1;
}

.visit-us-map {
    border: 0;
    width: 100%;
    height: 100%;
    filter: invert(90%) contrast(83%) hue-rotate(180deg);
}

.visit-us-title-text {
    font-size: 50px;
    font-weight: bolder;
    color: #c5a975;
    text-align: center;
}

.visit-us-subtitle-text {
    font-size: 25px;
    font-weight: bolder;
    color: #c5a975;
    text-align: center;
}

@media only screen and (max-height: 700px) { 
    .contact-page-container {
        height: 1500px;
    }
}

@media only screen and (max-width: 768px) { 
    .contact-page-container {
        height: 1500px;
    }
    
    .contact-us-container {
        height: 700px;
        flex-direction: column;
    }

    .contact-us-text-container {
        width: 100%;
        max-width: 1000px;
    }
    
    .contact-us-details-container {
        width: 100%;
        max-width: 1000px;
    }

    .contact-page-container {
        padding: 2%;
    }

    .visit-us-container {
        flex-direction: column;
        height: 700px;
    }

    .visit-us-text-container {
        width: 100%;
        max-width: 1000px;
    }
    
    .visit-us-map-container {
        width: 100%;
        max-width: 1000px;
        height: 50%;
        aspect-ratio: 1 / 1;
    }
}

