::-webkit-scrollbar {
    width: 20px;
}
::-webkit-scrollbar-track {
    background: #262626;
}
::-webkit-scrollbar-thumb {
    background: #c5a975;
    border: 3px #262626 solid;
}

::selection {
    background: #555555;
}

.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    animation-name: background-anim;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    padding: 25px 0;
}

.featured-container {
    display: flex;
    width: 100%;
    max-width: 1000px;
    height: 200px;
    justify-content: center;
    margin-top: 2vh;
    border: solid #c5a975;
    background-color: #262626;
}

.featured-text {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bolder;
    color: #c5a975;
}

.video-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1000px;
    align-items: center;
    margin-top: 25px;
    /* background-color: red; */
}

.youtube-vid {
    aspect-ratio: 16 / 9;
    width: 100%;
    margin-top: 25px;
}

.home-section-reverse-container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
    justify-content: space-evenly;
}

.home-section-container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
    justify-content: space-evenly;
}

.side-text-box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #262626;
    border: solid #c5a975;
    width: 100%;
    max-width: 700px;
}

.side-text-box-text {
    font-size: 4vw;
    font-weight: bolder;
    text-align: center;
    margin: 10px;
    color: #c5a975;
    background-image: linear-gradient(45deg, #ffdd9e, #6e5a3b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.side2-text-box-text {
    font-size: 5vw;
    font-weight: bolder;
    text-align: center;
    margin: 10px;
    color: #c5a975;
    background-image: linear-gradient(45deg, #ffdd9e, #6e5a3b);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-box-container {
    display: flex;
    justify-content: center;
    background-color: #262626;
    border: solid #c5a975;
    width: 100%;
    max-width: 1000px;
    margin-top: 0px;
}

.text-box-text {
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    color: #c5a975;
}

.text-box-text2 {
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    color: #c5a975;
}

.large-img-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin-top: 25px;
}

.large-img {
    width: 100%;
    padding: 10px;
}

.large-imgs-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 25px;
}

.img-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin-top: 25px;
}

.img-grid-row-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px;
}

.img-grid-row-container2 {
    display: flex;
    justify-content: center;
    width: 50%;
    padding: 25px;
}

.img-grid-half-row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.temp-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-img {
    width: 45%;
    height: auto;
}

.grid-img1 {
    width: 50%;
    height: auto;
}

.grid-img2 {
    width: 100%;
    height: auto;
}

.grid-img3 {
    width: 90%;
    height: auto;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    margin-top: 25px;
}

.map-location {
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1 / 1;
    margin: 10px;
    border: solid #c5a975;
    line-height: 0;
    margin-bottom: 20px;
}

.google-maps {
    border: 5px;
    width: 100%;
    aspect-ratio: 1 / 1;
    filter: invert(90%) contrast(83%) hue-rotate(180deg);
}

.location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.location-text-container {
    margin-top: 25px;
    width: 50%;
    background-color: #262626;
    color: #c5a975;
    padding: 10px;
    border: solid #c5a975;
}

.location-title-text {
    font-size: 20px;
    font-weight: bold;
}

.location-text {
    margin-top: 25px;
}

.contact-text-big-container {
    display: flex;
}

.contact-text-container {
    margin-left: 25px;
    margin-top: 25px;
    background-color: #262626;
    color: #c5a975;
    padding: 10px;
    border: solid #c5a975;
    width: 50%;
}

.contact-title-text {
    font-size: 20px;
    font-weight: bold;
}

.contact-text {
    margin-top: 25px;
}

.email {
    color: #777777;
}

.follow-us-container {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    align-items: center;
    background-color: #262626;
    color: #c5a975;
    border: solid #c5a975;
    width: 50%;
    max-width: 500px;
    justify-content: center;
    padding: 2%;
}

.follow-us-text {
    font-size: 20px;
    font-weight: bold;
    color: #c5a975;
}

.social-media-button {
    font-size: 25px;
    background-color: #c5a975;
    color: #262626;
    border-radius: 10px;
    border: solid 5px #262626;
    padding: 10px;
    margin: 25px;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transition: font-size 100ms ease-out 100ms
}

.social-media-button:hover {
    animation-name: hover;
    font-size: 30px;
}

@keyframes hover {
    0% {
        background-color: #c5a975;
        color: #262626;
        border: solid 5px #262626;
    }
    50% {
        background-color: #262626;
        color: #c5a975;
        border: solid 5px #c5a975;
    }
    100% {
        background-color: #c5a975;
        color: #262626;
        border: solid 5px #262626;
    }
}

@keyframes background-anim {
    0% {
        background-color: #555555;
    }
    25% {
        background-color: #262626;
    }
    50% {
        background-color: #6b5d44;
    }
    75% {
        background-color: #262626;
    }
    100% {
        background-color: #555555;
    }
}

@media only screen and (max-width: 1100px) {
    .img-grid-row-container2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        height: 100%;
        width: 50%;
    }

    .grid-img {
        padding: 10px;
        width: 75%;
    }

    .grid-img1 {
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 800px) {
    .home-section-reverse-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .home-section-container {
        flex-direction: column;
    }
    
    .img-grid-row-container {
        flex-direction: column;
        align-items: center;
    }

    .img-grid-half-row-container {
        margin-top: 10px;
        width: 100%;
    }

    .contact-container {
        flex-direction: column;
        align-items: center; 
    }

    .side-text-box-text {
        font-size: 6vw;
    }
    
    .side2-text-box-text {
        font-size: 6vw;
    }

    .img-grid-row-container2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .grid-img {
        width: 45%;
        padding: 0px;
    }
}