.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
    animation-name: background-anim;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.faq-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background-color: #262626;
    border: solid #c5a975;
    justify-content: center;
    padding: 25px;
}

.faq-title-text {
    font-size: 50px;
    font-weight: bolder;
    color: #c5a975;
}

.faq-subtitle-text {
    color: #bbb;
}

.question-container {
    display: flex;
    flex-direction: column;
    background-color: #262626;
    border: solid #c5a975;
    width: 75%;
    max-width: 1000px;
    padding: 25px;
}

.question-container2 {
    width: 50%;
    display: flex;
    justify-content: center;
}

.question-title-text {
    font-size: 50px;
    color: #c5a975;
}

.question-title-text1 {
    font-size: 4vw;
    color: #c5a975;
    text-align: center;
}

.question-title-text2 {
    font-size: 3.5vw;
    color: #c5a975;
    text-align: center;
}

.accent {
    height: 5px;
    width: 75px;
    background-color: #c5a975;
    margin-top: 10px;
    margin-bottom: 10px;
}

.question-text {
    font-size: 2.5vw;
    color: #c5a975;
}

@media only screen and (max-width: 800px) {
    .question-container {
        width: 100%;
    }

    .question-container2 {
        width: 100%;
    }

    .question-text {
        font-size: 25px;
        color: #c5a975;
    }

    .question-title-text1 {
        font-size: 30px;
        color: #c5a975;
        text-align: center;
    }

    .question-title-text2 {
        font-size: 20px;
        color: #c5a975;
        text-align: center;
    }
}

@keyframes background-anim {
    0% {
        background-color: #555555;
    }
    25% {
        background-color: #262626;
    }
    50% {
        background-color: #6b5d44;
    }
    75% {
        background-color: #262626;
    }
    100% {
        background-color: #555555;
    }
}

